import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag"


// Create a new store instance.
const store = createStore({
    state() {
        return {
            loading: false,
            menuOpen: false,
            showLightbox: false,
            lightboxData: {},
            app: {
                apiUrl: process.env.VUE_APP_API_URL,
                cdnUrl: process.env.VUE_APP_CDN_URL,
                siteUrl: process.env.VUE_APP_SITE_URL
            },
            galleryMaxRows: 0,
        }
    },
    mutations: {
        setLoading(state, loading) {
            state.loading = loading;
        },
        showLightbox(state, visibility) {
            state.showLightbox = visibility;
        },
        openLightbox(state, data) {
            state.showLightbox = true;
            state.lightboxData = data;
        },
        menuOpen(state, status) {
            state.menuOpen = status;
        },
    },
    getters: {
        loading(state) {
            return state.loading;
        },
        lightboxState(state) {
            return state.showLightbox;
        },
        lightboxData(state) {
            return state.lightboxData;
        },
        apiEndpoint(state) {
            return state.app.apiEndpoint;
        },
        assetEndpoint(state) {
            return state.app.assetEndpoint;
        },
        menuOpen(state) {
            return state.isMobile;
        },
    }
})

const VueGtagConfig = {
    config: { 
        id: "G-0XK5RW2TVM"
    }
};


const Vm = createApp(App).use(store).use(router).use(VueGtag,VueGtagConfig,router).mount('#app')

Vm.$router.beforeEach(function (to, from, next) {


    Vm.$store.state.menuOpen = false;

    if (to.path != from.path || to.path == '/') {
        // Display loader
        Vm.$store.commit('setLoading', true);

        // Set TITLE tag
        const pageTitle = (to.meta.title) ? to.meta.title + ' – Mickie C. Storm' : 'Mickie C. Storm';
        document.getElementsByTagName('title')[0].innerHTML = pageTitle;    

        // Set META DESCRIPTION tag
        const metaDescription = document.querySelector('meta[name="description"]');
        metaDescription.content = to.meta.description

        next();
    }

})


// Log message
console.log("%c===============================================", "color:#CCC")
console.log("%c███████╗████████╗ ██████╗ ██████╗ ███╗   ███╗\n██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗████╗ ████║\n███████╗   ██║   ██║   ██║██████╔╝██╔████╔██║\n╚════██║   ██║   ██║   ██║██╔══██╗██║╚██╔╝██║\n███████║   ██║   ╚██████╔╝██║  ██║██║ ╚═╝ ██║\n╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚═╝     ╚═╝", "color:#555");; //eslint-disable-line
console.log("%c===============================================", "color:#CCC")
console.log("I assume you are checking out my code to see how this crap was jammed together? I can assure this is not my best work – sometimes you just gotta get it done... Anyway, \"I'll refactor that later!\"\n\n%cIf you like the site, hit me up on github @mickiestorm and I'll be more than happy to share the source :)", "color:green; font-weight:bold;")
console.log("%c===============================================", "color:#CCC")
console.log("%cBuilt with Vue 3, Vue Router, Vuex and Strapi.io", "font-weight:bold;")
console.log("%c===============================================", "color:#CCC")