<template>
  <div class="contact-page">
		<div class="inner-page">
      <div class="content-container">
        Redirecting...
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        loading: true,
      };
    },
    mounted(){
      this.$nextTick(function () {
        this.$store.commit('setLoading', false);
      });
    },
  }
</script>
