<template>
  <div class="sidebar" :class="{open: menuOpen}">
      <div class="logo">
          <h1>Mickie C. <span>Storm</span></h1>
          <router-link to="/"><img alt="Mickie C. Storm" src="../../assets/images/logo-mickie-c-storm.svg"></router-link>
      </div>
      <div class="navigation">
          <nav>
              <ul>
                  <li><router-link to="/about-mickie-c-storm">Profile & CV</router-link></li>
                  <li><router-link :class="isWorkItem" to="/work-portfolio">Selected Work</router-link></li>
                  <!--<li><router-link to="/investments-and-projects">Investments & Projects</router-link></li>-->
                  <li><router-link to="/contact">Contact</router-link></li>
                  <li class="login"><router-link to="/login">Client Login</router-link></li>
              </ul>
          </nav>
      </div>
      <div class="footer">
          <nav>
              <ul>
                  <li><router-link to="/legal/terms-and-conditions">Terms</router-link></li>
                  <li><router-link to="/legal/cookies">Cookies</router-link></li>
                  <li><router-link to="/legal/privacy">Privacy</router-link></li>
              </ul>
          </nav>
          <span>Copyright {{ new Date().getFullYear() }}. All rights reserved.</span>
      </div>
  </div>
  <div class="mobile-nav-btn" :class="{open: menuOpen}" @click="toggleMenu()">
      <span></span><span></span><span></span>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
    msg: String
  },
  data(){
    return {
    }
  },
  methods: {
    toggleMenu() {
        if (this.$store.state.menuOpen){
            this.$store.commit('menuOpen', false);
        } else {
            this.$store.commit('menuOpen', true);
        }
    }
  },
  computed: {
    isWorkItem: function () {
        if (this.$route.name == "work-item"){
            return 'router-link-active'
        } else {
            return ''
        }
    },
    menuOpen() {
        return this.$store.state.menuOpen;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>