import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '',
      description: ''
    },
    beforeEnter() {
        window.location.replace("/about-mickie-c-storm");
    },
    component: Home
  },
  {
    path: '/linkedin',
    name: 'linkedin',
    meta: {
      title: 'Redirecting to LinkedIn',
      description: ''
    },
    beforeEnter() {
        window.location.replace("https://www.linkedin.com/in/mickiestorm");
    },
    component: Home
  },
  {
    path: '/about-mickie-c-storm',
    name: 'About',
    meta: {
      title: 'Profile & CV',
      description: 'Profile and CV of Mickie C. Storm. Design – Technical Creative Director with 15+ years of global hands-on experience...'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/work-portfolio',
    name: 'Work and Acheivements',
    meta: {
      title: 'Selected Work',
      description: 'Selected work and portfolio of Mickie C. Storm. Design – UI/UX, 3D/CAD, Development, Photography, Video, Print'
    },
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio.vue'),
  }, 
  {
    path: '/work/:itemname',
    name: 'work-item',
    meta: {
      title: 'Investments & Projects',
      description: ''
    },
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/SinglePortfolioItem.vue'),
  },
  {
    path: '/investments-and-projects',
    name: 'Investments and Projects',
    meta: {
      title: 'Investments & Projects',
      description: ''
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Investments.vue')
  }, 
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Contact',
      description: 'Contact details for Mickie C. Storm. Phone numbers: US: +1 (310) 880-7313 – DK: +45 22 26 89 89 – UK: +44 0 746 993 7234 – ES: +36 902 84 81 95.'
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/legal/:itemname',
    name: 'Legal',
    meta: {
      title: 'Legal',
      description: 'Legal information about terms and conditions, privacy, and cookies on Storm.ms'
    },
    component: () => import(/* webpackChunkName: "legal" */ '../views/LegalPageSingle.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: 'Client Login',
      description: 'Login to access files, contracts and invoices. Use the forgot password link if you are having issues logging in.'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/page-not-found',
    name: 'Page not found',
    meta: {
      title: 'Page not found',
      description: 'This page could not be found. Please try again, or use the contact form to report broken links.'
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/ErrorPage404.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404 Error',
    meta: {
      title: '404 Page not found...',
      description: 'This page could not be found. Please try again, or use the contact form to report broken links.'
    },
    beforeEnter(details) {
      window.location.replace("/page-not-found?url="+details.fullPath);
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/ErrorPage404.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
