<template>
	<div id="lightbox" @click="focusOutClickLightbox($event)">
		<div class="content"></div>
		<div class="overlay"></div>
		<div class="button arrow left" @click="navigatePrevious($event)"><img alt="Lightbox left navigation arrow" @click="navigatePrevious($event)" src="../../assets/icons/lightbox-arrow.svg"></div>
		<div class="button arrow right" @click="navigateNext($event)"><img alt="Lightbox right navigation arrow" @click="navigateNext($event)" src="../../assets/icons/lightbox-arrow.svg"></div>
		<div class="button close" @click="closeLightbox($event)"><img alt="Lightbox close button" @click="closeLightbox($event)" src="../../assets/icons/lightbox-close.svg"></div>
		<div class="description">
			<span class="title">Title goes here</span>
			<span class="caption">Some text goes right here</span>
		</div>
	</div>
</template>
<script>

	export default {
		name: "Lightbox",
		data() {
			return {
				loadingOld: false,
				lightboxContent: '',
			};
		},
		computed: {
			show() {
				return this.$store.getters.lightboxState;
			},
			lightboxData(){
				return this.$store.getters.lightboxData;
			}
		},
		watch: {
			show (showLightbox) {

					const lightbox = document.getElementById('lightbox');

					if (showLightbox == true) {
						lightbox.classList.add('show');
						document.addEventListener("keydown", this.keyboardCommand);
				
					} else {
						lightbox.classList.remove('show');
						document.removeEventListener("keydown", this.keyboardCommand);
					}
			},
			lightboxData(lightboxData){
				this.renderLightbox(lightboxData);
			}
		},
		methods: {
			renderLightbox(lightboxData){

				let html = '';

				lightboxData.items.forEach(function(item,index){

					let activeClass = '';
	
					// Active class
					if(index == lightboxData.active){
						activeClass = 'active'
					}
					

					html += `<div class="slide ${activeClass} ${item.imageOrientiation}">`;

						// RENDER IMAGE
						if (item.renderer == 'image'){
							html += 
							`<div class="inner"><img style="width:${item.imageWidth}px; height:${item.imageHeight}px; max-width:100%; max-height:100%; object-fit: contain;" src="${this.$store.state.app.cdnUrl}${item.image}"/></div><div class="loader"><div class="dot-overtaking"></div><span>LOADING IMAGE...</span></div>`
						}
						if (item.renderer == 'youtube'){
							const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
							const match = item.videoLink.match(regExp);
							const youtubeVideoID = (match&&match[7].length==11)? match[7] : false;
							html += 
							`<div class="inner"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/${youtubeVideoID}?enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen></iframe></div>`
						}


					html += `</div>`;


				}.bind(this))

				// insert markup
				const lightboxContent = document.getElementById('lightbox').querySelector('.content');
				lightboxContent.innerHTML = html;

				// Add event listeners to display pictures after loaded
				const images = lightboxContent.querySelectorAll('img')
				images.forEach(function(item){
					item.addEventListener("load", function() {
						item.parentElement.parentElement.classList.add('loaded');
					});
					setTimeout(function(){
						item.parentElement.parentElement.classList.add('loading');
					}.bind(item,item),150);
				}.bind(this));

				//this.lightboxContent = html;

				this.updatePosition();

			},
			closeLightbox(){
				this.youtubeStopAll();
				this.$store.commit('showLightbox', false);
			},
			focusOutClickLightbox(event){
				if (event.target.classList.contains('overlay') || event.target.classList.contains('inner')){
					this.closeLightbox();
				}
			},
			keyboardCommand(event){
				event.preventDefault();
				switch(event.keyCode) {
					case 27:
						this.closeLightbox();
						break;
					case 37:
						this.navigatePrevious();
						break;
					case 39:
						this.navigateNext();
						break;
					default:
						// code block
				} 
			},
			navigateNext(){
				const slides = document.getElementById('lightbox').querySelector('.content');
				
				let activeSlideIndex = 0;
				let nextSlideIndex = 0;

				for (let i = 0; i < slides.children.length; i++) {
					if (slides.children[i].classList.contains('active')){
						activeSlideIndex = i;
						nextSlideIndex = (i == slides.children.length - 1) ? i : (i+1);
					}
				}

				slides.children[activeSlideIndex].classList.remove('active');
				slides.children[nextSlideIndex].classList.add('active');

				this.youtubePlay(nextSlideIndex);
				this.updatePosition();


			},
			navigatePrevious(){
				const slides = document.getElementById('lightbox').querySelector('.content');
				
				let activeSlideIndex = 0;
				let nextSlideIndex = 0;

				for (let i = 0; i < slides.children.length; i++) {
					if (slides.children[i].classList.contains('active')){
						activeSlideIndex = i;
						nextSlideIndex = (i == 0) ? i : (i-1);
					}
				}

				slides.children[activeSlideIndex].classList.remove('active');
				slides.children[nextSlideIndex].classList.add('active');
				
				this.youtubePlay(nextSlideIndex);
				this.updatePosition();

			},
			updatePosition(){

				const lightbox = document.getElementById('lightbox');
				const slides = lightbox.querySelector('.content');
				
				let activeSlideIndex = 0;

				for (let i = 0; i < slides.children.length; i++) {
					if (slides.children[i].classList.contains('active')){
						activeSlideIndex = i;
					}
				}

				lightbox.classList.remove('start');
				lightbox.classList.remove('end');

				if (activeSlideIndex == slides.children.length -1){
					lightbox.classList.add('end')
				}

				if (activeSlideIndex == 0){
					lightbox.classList.add('start')
				}


				// Update Labels
				const itemTitle = this.lightboxData.items[activeSlideIndex].title;
				const itemCaption = this.lightboxData.items[activeSlideIndex].description;

				if (itemTitle) {
					lightbox.querySelector('.description .title').textContent = itemTitle;
					lightbox.querySelector('.description .title').style.display = 'block';
				} else {
					lightbox.querySelector('.description .title').style.display = 'none';
				}

				if (itemCaption) {
					lightbox.querySelector('.description .caption').textContent = itemCaption;
					lightbox.querySelector('.description .caption').style.display = 'block';
				} else {
					lightbox.querySelector('.description .caption').style.display = 'none';
				}			

			},
			youtubeStopAll(){
				
				const slides = document.getElementById('lightbox').querySelector('.content');

				// Stop all instances
				const iframes = slides.querySelectorAll('iframe');
				iframes.forEach(function(iframe){
					iframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), 'https://www.youtube.com');
				});

			},
			youtubePlay(playSlideIndex){
				
				this.youtubeStopAll();

				// Play active slide
				const slides = document.getElementById('lightbox').querySelector('.content');
				const playSlide = slides.children[playSlideIndex].querySelector('iframe');
				if (playSlide) {
					playSlide.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'playVideo' }), 'https://www.youtube.com');
				}

			}
		},
		mounted() {

		},
	};



</script>