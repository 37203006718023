<template>
    <Sidebar/>
    <div class="content">
      <router-view class="page" v-slot="{ Component }">
        <transition name="page-transition">
          <component :is="Component" />
        </transition>
      </router-view>
      <Spinner />
    </div>
    <Lightbox />
</template>

<style lang="scss">
  
  @import "scss/main.scss";
  
</style>

<script>

  // import './scss/main.scss';
  import Spinner from "@/components/loader/Spinner.vue";
  import Lightbox from "@/components/lightbox/Lightbox.vue";
  import Sidebar from '@/components/layout/Sidebar.vue';

  export default {
    components: {
      Spinner,
      Lightbox,
      Sidebar,
    },
    mounted(){
      //
    }
  }

</script>