<template>
  <div id="loader" class="loading-spinner">
    <div class="dot-overtaking"></div>
    <span class="label">LOADING...</span>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  data() {
    return {
      loadingOld: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
	watch: {
    loading (showLoader) {
			const loader = document.getElementById("loader");
			if (showLoader == true) {
				loader.classList.add('show');
			} else {
				loader.classList.remove('show');
			}
    }
  },
  mounted() {

	},
};
</script>